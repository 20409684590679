<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <label> {{ $t('admin.duplicate.calendar.name') }}</label>
            <i @click="onCopy('name')" class="pointer ml-2 fa-15x" :class="icon"></i>

            <span class="d-flex w-100">
                <d-text-field
                  v-model="calendar.name"
                  error-text="validation.text-input-three-characters"
                  class="btn-full-width"
                  class-name="bg-white"
                  size="sm"
                />
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-1" cols="12">
        <b-row>
          <b-col cols="12">
            {{  $t('admin.duplicate.calendar.holidays')}}
            <i @click="onCopy('dates')" class="pointer ml-2 fa-15x" :class="icon"></i>
          </b-col>
          <b-col
            v-for="(holiday, index) of holidaysValues"
            :key="'holiday-' + index"
            cols="6"
            class="mt-2 d-flex"
          >
            <vs-checkbox
              v-model="calendar.dates"
              vs-name="dates"
              :vs-value="$moment(holiday.date).format('YYYY-MM-DD')"
              color="primary"
            >
              {{ holiday.name }} - {{ $moment(holiday.date).format('DD/MM/YYYY') }}
            </vs-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="hasBorder" class="border-bottom-black mt-3 mb-4"/>

  </div>
</template>
<script>
import * as DateHolidays from 'date-holidays';
import {cloneData} from "@/utils/form";

export default {
  data: () => ({
    holidaysValues: []
  }),
  props: {
    calendar: {
      type: Object,
      default: this
    },
    icon: {
      type: String,
      default: 'icofont icofont-download-alt'
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCopy(key) {
      const payload = {};
      payload[key] = this.calendar[key];

      this.$store.commit('calendar/updateCalendarCheckedList', payload)
    },
    loadHolidays(selectedDate = new Date()) {
      this.holidaysValues = [];

      const holidays = new DateHolidays.default();

      holidays.init('FR');

      const days = cloneData(holidays.getHolidays(this.$moment(selectedDate).year()));

      for (let day of days) {
        if (day.type === 'public') {
          this.holidaysValues.push(day);
        }
      }
    },
  },
  mounted() {
    this.$vs.theme({
      primary: '#0B2772',
    });
    this.loadHolidays();
  }
}
</script>
<style lang="scss" scoped>
/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}
</style>
